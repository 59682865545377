<div *ngIf="workOrder" class="work-order-details">
	<mat-card *ngIf="!!inspectionItems && inspectionItems.length > 0">
		<mat-card-header>
			<mat-card-title>Vehicle Inspection Report</mat-card-title>
			<mat-card-subtitle>
				<ma-customer-vehicle-summary [vehicle]="workOrder?.Vehicle"></ma-customer-vehicle-summary>
				<div class="spacer"></div>
				<div>{{workOrder.Customer?.Name}}</div>
				<div>
					Order #{{workOrder.Id?.substring(3)}}
					<span *ngIf="workOrder && workOrder.InspectionCompletionDate" class="mat-body-1">
						- {{workOrder.InspectionCompletionDate.slice(6, -2) | date: 'shortDate' }}
					</span>
				</div>
				<span  *ngIf="workOrder && workOrder.ServiceAdvisorName">
					Service Advisor: {{workOrder.ServiceAdvisorName | titlecase }}
				</span>
			</mat-card-subtitle>
		</mat-card-header>
		<mat-card-content>
			<mat-divider></mat-divider>
			<div class="mat-h2 customer-concerns-title">What you brought it in for</div>
			<ma-inspection-report-customer-concerns [inspectionItems]="inspectionItems"></ma-inspection-report-customer-concerns>
		</mat-card-content>
	</mat-card>
</div>

<!-- Routes made available based on different views of the inspection -->
<nav mat-tab-nav-bar *ngIf="inspectionId">
	<a mat-tab-link
	   *ngFor="let routeLink of routeLinks;"
	   [routerLink]="routeLink.link"
	   routerLinkActive=""
	   #rla="routerLinkActive"
	   [active]="routeLink.isActive"
	   [routerLinkActiveOptions]="{exact: true}">
		{{routeLink.label}}
	</a>
</nav>

<!-- Detailed breakdown of the inspection -->
<router-outlet></router-outlet>
